import React from "react"
import { graphql, Link } from "gatsby"

import LargeHeader from "../components/LargeHeader"
import ArticleList from "../components/ArticleList"
import LargeSection from "../components/LargeSection"
import SEO from "../components/seo"

export default function Home({ data }) {
  const { image1 } = data // allMdx,
  //const posts = allMdx.edges.map(item => item.node) || null
  const posts = null

  /*
  <p>I love building web applications. I enjoy <strong>JavaScript</strong> as my language of choice for backend & frontend, but I have quite a track record building <strong>Python</strong> & <strong>PHP</strong> applications. I am a fan of <strong>AWS</strong> and like to think architecture & infrastructure. I try to contibute something to the internet from time to time</p>
   */

  return (
    <>
      <SEO
        description="Hi, I'm Thomas! Full-Stack Developer & Solutions architect. I love building web apps."
        article={false}
      />
      <LargeHeader image={image1} alt="Thomas Zipner">
        <h1 className="giant">Hi, I am Thomas!</h1>
        <h3 className="primary">Full-Stack Developer & Solutions architect</h3>

        <p>
          I love building <strong>web apps</strong>. I use{" "}
          <strong>JavaScript</strong>, <strong>Python</strong>,{" "}
          <strong>PHP</strong>, <strong>HTML</strong> & <strong>CSS</strong>. I
          enjoy <strong>designing systems</strong> with{" "}
          <strong>cloud technologies</strong> and feel at home on{" "}
          <strong>AWS</strong>.
        </p>
        {/* <p>I ♥️ <strong>MVP</strong>ing, prototyping & testing new product ideas.</p> */}
        <p>
          You can read more about me <Link to="/about-me">here</Link>.
        </p>
        {/* or check out one of <Link to="/blog">my articles</Link> */}
      </LargeHeader>

      {posts ? (
        <LargeSection className="diagonal-section diagonal-section--bottom-straight">
          <section className="section" id="blog">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12">
                  <h2 className="h1 giant primary-color">Hits from the blog</h2>
                  <ArticleList posts={posts} />
                </div>
              </div>
            </div>
          </section>
        </LargeSection>
      ) : null}
    </>
  )
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "thommy-1200.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1250) {
          ...GatsbyImageSharpFluid_noBase64
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`

/*
 allMdx(
      filter: { fields: { collection: { eq: "post" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 10
      skip: 0
    ) {
      totalCount
      edges {
        node {
          id
          timeToRead
          excerpt
          fields {
            collection
            slug
          }
          frontmatter {
            title
            description
            date(formatString: "MMMM DD, YYYY")
            category
            image {
              ...BlogPreviewImageFields
            }
          }
        }
      }
    }
    */
